<template>
  <div>
    <div class="page-title">
      <h3>Бригады</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Добавить'"
           @click="$router.push('/teams/add')">
          <i class="material-icons">add</i>
        </a>
      </span>
    </div>

    <Loader v-if="loading"/>

    <p class="center" v-else-if="!records.length">
      {{'Нет записей'}}
    </p>

    <section v-else>
      <TeamsTable :records="items"/>

      <Paginate
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler"
        :prev-text="'Назад'"
        :next-text="'Вперед'"
        :container-class="'pagination'"
        :page-class="'waves-effect'"
      />
    </section>
  </div>
</template>

<script>
  import paginationMixin from '@/mixins/pagination.mixin'
  import TeamsTable from '@/components/TeamsTable'

  export default {
    name: 'teams',
    metaInfo() {
      return {
        title: this.$title('Бригады')
      }
    },
    mixins: [paginationMixin],
    data: () => ({
      loading: true,
      records: [],
      currentPage: 1,
      pageSize: 50,
    }),
    async mounted() {
      this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) : 1

      await this.loadResult()
      this.loading = false
    },
    methods: {
      async setPaginationPage(num) {
        this.currentPage = num
        await this.loadResult()
      },
      async loadResult() {
        const response = await this.$store.dispatch('fetchTeams', {page: this.currentPage, size: this.pageSize})
        this.records = response.teams || []
        this.setupPagination(this.setPaginationPage, this.currentPage, 50, this.records, response.count)
      },
    },
    components: {
      TeamsTable
    }
  }
</script>
