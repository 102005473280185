<template>
  <div>
    <table>
      <thead>
      <tr>
        <th class="center-align" style="width: 100px">ID</th>
        <th>Название</th>
        <th style="width: 200px">Отдел</th>
        <th style="width: 200px">Владелец</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(record, idx) of records" :key="record.id">
        <td class="center-align">
          <router-link :to="{path: '/teams/' + record.id}"
                       v-tooltip="'Открыть'"
                       class="btn-small btn blue darken-4">
            {{ record.id }}
          </router-link>
        </td>
        <td>{{ record.name }}</td>
        <td>
          {{ record.department.name }}
        </td>
        <td>
          {{ record.engineer.last_name }} {{ record.engineer.first_name }}
        </td>
        <td>
          <a
            v-tooltip="'Завершить работу бригады'"
            class="btn-small btn blue darken-4"
            @click="releaseTeam(record.id)">
            Отпустить
          </a>
          &nbsp;
          <a
            v-tooltip="'Завершить рабочие смены бригады'"
            class="btn-small btn red darken-4"
            @click="openCloseWorkingShiftModal(record.id)">
            Завершить
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <div id="modalCloseWorkingShift" class="modal">
      <div class="modal-content">
        <h4>Завершение рабочих смен бригады</h4>
        <p>Внимание! Трекинг всех участников бригады будет остановлен. Вы уверены?</p>
      </div>
      <div class="modal-footer">
        <a class="waves-effect red darken-4 btn-flat white-text" v-on:click="closeWorkingShift">Завершить</a>
        &nbsp;&nbsp;&nbsp;
        <a class="modal-close waves-effect blue darken-4 btn-flat white-text">Отмена</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    records: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    selectedTeam: 0,
    modalCloseWorkingShift: null,
  }),
  async mounted() {
    setTimeout(() => {
      this.modalCloseWorkingShift = M.Modal.init(document.querySelector('#modalCloseWorkingShift'));
    })
  },
  methods: {
    async releaseTeam(teamId) {
      await this.$store.dispatch('releaseTeam', teamId)
      this.$router.go(0)
    },
    openCloseWorkingShiftModal(teamId) {
      this.selectedTeam = teamId
      this.modalCloseWorkingShift.open();
    },
    async closeWorkingShift() {
      await this.$store.dispatch('closeWorkingShift', this.selectedTeam)
      this.$router.go(0)
    },
  },
  destroyed() {
    this.modalCloseWorkingShift.destroy();
  },
}
</script>
